// This import must go first
import '../src/http-setup';

import '@mentimeter/ragnar-tailwind-config/globals.css';
import * as React from 'react';
import {
  appWithTranslation,
  type UserConfig,
  useTranslation,
} from 'next-i18next';
import type { AppContext, AppProps, AppInitialProps } from 'next/app';
import dynamic from 'next/dynamic';
// eslint-disable-next-line eslint-custom-plugin/no-next-router
import Router from 'next/router';
import { billingRules, useSubscriptions } from '@mentimeter/billing';
import {
  addResourceBundles,
  MentimeterI18nInitializer,
  registerResourceBundles,
} from '@mentimeter/i18n';
import OneTrustScript, { useOneTrustReady } from '@mentimeter/onetrust';
import {
  type IRenderer,
  Provider,
  ThemeProvider,
} from '@mentimeter/ragnar-react';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';
import { stripAllNonNumericChars } from '@mentimeter/ragnar-utils';
import {
  getSplitData,
  getSplitDataSync,
  getVisitorToken,
} from '@mentimeter/splitio';
import {
  core,
  type UserFlagsT,
  type UserResponseT,
} from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';
import { themes } from '@mentimeter/ragnar-colors';
import { getStoredUtmFlags, parseAndStoreUtmParams } from '@mentimeter/utm';
import { locales as authLocales } from '@mentimeter/auth';
import { locales as plansContentLocales } from '@mentimeter/plans-content';
import { locales as checkoutModalPackage } from '@mentimeter/checkout-modal';
import { locales as checkoutUiPackageLocales } from '@mentimeter/checkout-ui';
import { locales as votingBarPackageLocales } from '@mentimeter/voting-bar';
import {
  getLocalUser,
  getSessionToken,
  removeLocalUserAndSessionToken,
  setLocalUser,
  USER_LOCALSTORAGE_KEY,
  isAuthorizedToInteractWithDevelopmentTools,
} from '@mentimeter/user';
import { setUser as setSentryUser } from '@sentry/nextjs';
import { TrackingMethods } from '@mentimeter/google-tracking';
import { DeviceProvider } from '@mentimeter/ragnar-device';
import i18nextConfig from 'next-i18next.config.cjs';
import { LocalizedWebsiteRedirect } from 'src/components/LocalizedWebsiteRedirect';
import { SSEProvider } from '@mentimeter/server-sent-events';
import { PublicEnvVars } from '@mentimeter/next-env-vars';
import { MazeScript } from '@mentimeter/maze';
import { DevelopmentToolsSetup } from '@mentimeter/development-tools/setup';
import createRenderer from '../src/createRenderer';
import { SplitIOSetup } from '../src/split-setup';
import { AppStateContext } from '../src/appState';
import '../src/polyfills';
// See NormalModuleReplacementPlugin:
import { trackVisitor } from '../src/trackVisitor';
import { userExperiments, visitorExperiments } from '../src/split-experiments';

if (typeof window !== 'undefined') {
  // Make sure we load hellojs for social auth to always work
  // since after login and sign up users are redirected to landing
  // page.
  require('hellojs');
}

// Register translations for auth package
registerResourceBundles(authLocales);

const _user = getLocalUser();
if (_user) setSentryUser({ id: String(_user.id) });

const ENABLE_INTERCOM = globalThis.__mentimeterEnv['NEXT_PUBLIC_ENABLE_INTERCOM'];
const INTERCOM_APP_ID = globalThis.__mentimeterEnv['NEXT_PUBLIC_INTERCOM_APP_ID'] as string;
const DISABLE_TRACKING = globalThis.__mentimeterEnv['NEXT_PUBLIC_DISABLE_TRACKING'];
const ONETRUST_URL = globalThis.__mentimeterEnv['NEXT_PUBLIC_ONETRUST_URL'] as string;
const ONETRUST_DOMAIN_SCRIPT = globalThis.__mentimeterEnv['NEXT_PUBLIC_ONETRUST_DOMAIN_SCRIPT'];

const DynamicIntercomLoader = dynamic(
  import('../src/components/IntercomLoader'),
  { ssr: false },
);

const DynamicLoadedNotFound = dynamic(
  import('../src/components/Error').then((x) => x.NotFound),
  { ssr: true },
);

const DynamicLoadedGeneralError = dynamic(
  import('../src/components/Error').then((x) => x.GeneralError),
  { ssr: true },
);

const DSC = {
  ...designSystemConfig,
  ...themes.light,
  //           0   1   2   3   4   5   6   7   8   9
  fontSizes: [12, 14, 16, 20, 24, 32, 36, 38, 48, 64],
  lineHeights: {
    none: 1,
    body: 1.4,
    heading: 1,
  },
};

const clientRenderer = createRenderer();
const breakpoints = DSC.breakpoints.map<number>(stripAllNonNumericChars);

interface PageProps {
  skipTracking: boolean;
  hasError: boolean;
  statusCode: number;
}

interface MyAppProps extends AppProps<PageProps> {
  renderer: IRenderer;
}

interface MyAppType extends React.FC<MyAppProps> {
  getInitialProps: (ctx: AppContext) => Promise<AppInitialProps>;
}

const MyApp: MyAppType = ({
  Component,
  pageProps,
  renderer = clientRenderer,
}: MyAppProps) => {
  let pageElement = null;
  const { hasError, statusCode, skipTracking } = pageProps;
  const [user, setUser] = React.useState<UserResponseT | null>(null);
  const { subscriptions } = useSubscriptions(!user);
  const { planCategory } = billingRules(subscriptions);
  const [visitorToken, setVisitorToken] = React.useState(getVisitorToken());
  const [userFinishedLoading, setUserFinishedLoading] = React.useState(false);

  const logout = async () => {
    await removeLocalUserAndSessionToken();
    setUser(null);
    setSentryUser(null);
  };

  const trackPageView = React.useCallback(() => {
    const localUser = getLocalUser();
    const { location } = window;
    const { preferredLanguage, country } = getSplitDataSync();

    if (DISABLE_TRACKING === 'true' || skipTracking) return;

    const payload = {
      event: 'Viewed page',
      properties: { Page: location.pathname },
    };

    trackVisitor(payload);

    if (localUser) {
      trackUser(payload);
    }

    TrackingMethods.trackPageView({
      page_location: location.pathname,
      page_title: document.title,
      country,
      language: preferredLanguage,
      logged_in: !!localUser,
      page_type: 'public',
      plan_type: planCategory,
      user_purpose: localUser?.flags.onboarding,
    });
  }, [skipTracking, planCategory]);

  React.useEffect(() => {
    const storageHandler = (event: StorageEvent) => {
      if (event.key === USER_LOCALSTORAGE_KEY) {
        // User object has changed
        setUser(getLocalUser());
      }
    };

    Router.events.on('routeChangeComplete', trackPageView);
    window.addEventListener('storage', storageHandler);

    return () => {
      Router.events.off('routeChangeComplete', trackPageView);
      window.removeEventListener('storage', storageHandler);
    };
  }, [trackPageView]);

  // triggers first, get user and set loading state
  React.useEffect(() => {
    const localUser = getLocalUser();
    const sessionToken = getSessionToken(localUser);

    parseAndStoreUtmParams(document.URL);

    if (sessionToken || localUser) {
      core()
        .users.fetchUser()
        .then(({ data }) => {
          setLocalUser(data);
          setUser((state) => ({ ...state, ...data }));
        })
        .catch(async (e) => {
          if (e.response?.data?.status === 401) {
            // intercomLogout(); does not work - needs timeout (?).
            // user session has been revoked
            await logout();
          }
        });
    }

    setUser(localUser);
    setUserFinishedLoading(true);
  }, []);

  // triggers second, set visitor token
  React.useEffect(() => {
    if (!userFinishedLoading) return;

    getSplitData(['country', 'preferredLanguage', 'visitorToken']).then(
      ({ visitorToken }) => {
        if (visitorToken) setVisitorToken(visitorToken);

        if (!ONETRUST_DOMAIN_SCRIPT) {
          // backup sending trackPageView in case OneTrust is disabled
          trackPageView();
        }
      },
    );

    // triggers third, save utm flags
    // this could be another effect, but we lack proper effect trigger since we get user from local storage and set it synchronously
    const localUser = getLocalUser();

    if (!localUser) return;

    const flags = getStoredUtmFlags(localUser.flags);

    if (!flags) return;

    core()
      .users.saveUtmFlags({
        flags: {
          utm_last:
            (flags.utm_last as UserFlagsT['utm_last']) ||
            localUser.flags?.utm_last,
          utm_first:
            (flags.utm_first as UserFlagsT['utm_first']) ||
            localUser.flags?.utm_first,
        },
      })
      .then(({ data }) => setLocalUser(data))
      .catch(async (error) => {
        if (error.response?.status === 401) {
          await logout();
        }
      });
  }, [userFinishedLoading, trackPageView]);

  // this will trigger when OneTrust is ready and cookie banner is loaded or when cookie categories are changed
  // the reason for that is GTM (and GA) wants us to trigger page view only after we set categories in OneTrust
  // won't trigger if OneTrust is disabled
  useOneTrustReady(trackPageView, { once: false });

  if (hasError) {
    if (statusCode === 404) {
      pageElement = <DynamicLoadedNotFound />;
    } else {
      pageElement = <DynamicLoadedGeneralError />;
    }
  } else {
    pageElement = <Component {...pageProps} />;
  }

  return (
    <>
      <PublicEnvVars />
      <InitMentimeterI18n>
        <LocalizedWebsiteRedirect />
        <SSEProvider>
          <ThemeProvider theme={DSC}>
            <DeviceProvider breakpoints={breakpoints}>
              <Provider renderer={renderer}>
                <AppStateContext.Provider
                  value={{ user, visitorToken, userFinishedLoading }}
                >
                  <OneTrustScript
                    oneTrustURL={ONETRUST_URL}
                    domainScript={ONETRUST_DOMAIN_SCRIPT}
                  />
                  <MazeScript />
                  <DynamicIntercomLoader
                    enableIntercom={ENABLE_INTERCOM === 'true'}
                    intercomAppid={INTERCOM_APP_ID}
                  />
                  <SplitIOSetup>
                    <DevelopmentToolsSetup
                      experiments={{
                        ...visitorExperiments,
                        ...userExperiments,
                      }}
                      isAuthorizedToInteractWithDevelopmentTools={isAuthorizedToInteractWithDevelopmentTools()}
                    >
                      {pageElement}
                    </DevelopmentToolsSetup>
                  </SplitIOSetup>
                </AppStateContext.Provider>
              </Provider>
            </DeviceProvider>
          </ThemeProvider>
        </SSEProvider>
      </InitMentimeterI18n>
    </>
  );
};

const InitMentimeterI18n = ({ children }: { children: React.ReactNode }) => {
  const { i18n: i18nextInstance } = useTranslation();

  addResourceBundles(i18nextInstance, [
    plansContentLocales,
    checkoutModalPackage,
    checkoutUiPackageLocales,
    votingBarPackageLocales,
  ]);

  return (
    <MentimeterI18nInitializer
      config={i18nextConfig}
      language={i18nextInstance.language}
    >
      {children}
    </MentimeterI18nInitializer>
  );
};

// This is needed to force Next.js to skip prerendering of static pages (Automatic Static Optimization).
// We cannot allow this optimization since we move the same build between different environments
// (stage -> prod).
MyApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return { pageProps };
};

export default appWithTranslation(
  MyApp,
  i18nextConfig as UserConfig,
) as MyAppType;
