import React, { type JSX } from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { addUnit } from '@mentimeter/ragnar-utils';
import { Box, type BoxT } from '../Box/Box';
import { ClickableIcon } from '../ClickableIcon';

interface TextInputIcon extends BoxT {
  icon: JSX.Element;
  iconLabel?: string | undefined;
  iconAction?: (() => void) | undefined;
  inputSize?: 'default' | 'compact';
  iconType?: 'leading' | 'trailing';
}

export const TextInputIcon = ({
  icon,
  iconLabel = undefined,
  iconAction = undefined,
  inputSize,
  iconType,
  ...rest
}: TextInputIcon) => {
  if (!icon) return;

  return (
    <Box
      className={cn(
        'absolute items-center justify-center h-full text',
        iconAction ? 'pointer-events-auto' : 'pointer-events-none',
        iconType === 'leading' && inputSize === 'default' ? 'pl-4 ' : 'pl-3',
        iconType === 'trailing' && 'right-0',
        iconType === 'trailing' && inputSize === 'default' ? 'pr-4' : 'pr-3',
      )}
      aria-hidden={!iconAction}
      {...rest}
    >
      {iconAction ? (
        <ClickableIcon
          alignItems="center"
          justifyContent="center"
          aria-label={iconLabel}
          onClick={iconAction}
          extend={({ theme }) => ({
            width: addUnit(theme.kosmosSpacing.space4),
            height: addUnit(theme.kosmosSpacing.space4),
          })}
        >
          {icon}
        </ClickableIcon>
      ) : (
        icon
      )}
    </Box>
  );
};
